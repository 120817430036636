import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import './AppBar.css';

const $ = window.$;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff'
    }
  }
});

const MENU_LABEL_MAP = {
  redemptionVoucher: {
    zht: '電子券',
    en: 'Redemption Voucher',
    zhs: '电子券',
    th: 'บัตรกำนัลไถ่ถอน',
    vi: 'Phiếu mua hàng'
  },
  language: {
    zht: '語言',
    en: 'Switch Language',
    zhs: '语言',
    th: 'ภาษา',
    vi: 'Chuyển đổi ngôn ngữ'
  },
  tc: {
    zht: '會員條款',
    en: 'Member Terms',
    zhs: '会员条款',
    th: 'เงื่อนไขการเป็นสมาชิก',
    vi: 'Điều khoản thành viên'
  },
  privacy: {
    zht: '私隱政策',
    en: 'Privacy Policy',
    zhs: '私隐政策',
    th: 'นโยบายความเป็นส่วนตัว',
    vi: 'Chính sách bảo mật'
  },
  qna: {
    zht: '常見問題',
    en: 'Q&A',
    zhs: '常见问题',
    th: 'Q&A',
    vi: 'Q&A'
  },
  cancel: {
    zht: '取消',
    en: 'Cancel',
    zhs: '取消',
    th: 'ยกเลิก',
    vi: 'Hủy bỏ'
  },
  onlineRedemptionRecord: {
    zht: '網上換領記錄',
    en: 'Online Redemption Record',
    zhs: '网上换领记录',
    th: 'บันทึกการไถ่ถอนออนไลน์',
    vi: 'Hồ sơ đổi thưởng trực tuyến'
  }
};

const LANGUAGE_LABEL_MAP = {
  zht: '繁體中文',
  en: 'English',
  zhs: '简体中文',
  th: 'ไทย',
  vi: 'tiếng Việt'
};

class AppMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      languageSettingOpen: false
    };
  }

  openLanguageSetting = () => {
    this.setState({languageSettingOpen: true});
  };

  closeLanguageSetting = () => {
    this.setState({languageSettingOpen: false});
  };

  changeLanguage = (language) => {
    const params = new URLSearchParams(window.location.search);
    params.set('lang', language);
    window.location.replace(`${window.location.pathname}?${params}`);
  };

  render() {
    const {redemptionVoucherLink, supportLanguages, lang, tcLink, privacyLink, qnaLink, onlineRedemptionRecordLink} = this.props;
    const {languageSettingOpen} = this.state;

    let languageSettingComponent = null;
    let languageSettingDialogComponent = null;
    if (supportLanguages.length > 1) {
      languageSettingComponent = (
        <ListItem button onClick={this.openLanguageSetting}>
          <ListItemText
            primary={MENU_LABEL_MAP.language[lang]}
            secondary={LANGUAGE_LABEL_MAP[lang]} />
        </ListItem>
      );

      languageSettingDialogComponent = (
        <Dialog open={languageSettingOpen} onClose={this.closeLanguageSetting} fullWidth={true}>
          <DialogTitle>{MENU_LABEL_MAP.language[lang]}</DialogTitle>
          <List dense={true}>
            {supportLanguages.map((supportLanguage, i) => (
              <ListItem key={i} button onClick={() => {this.changeLanguage(supportLanguage)}}>
                <ListItemText primary={LANGUAGE_LABEL_MAP[supportLanguage]} />
              </ListItem>
            ))}
          </List>
          <DialogActions>
            <Button onClick={this.closeLanguageSetting}>{MENU_LABEL_MAP.cancel[lang]}</Button>
          </DialogActions>
        </Dialog>
      );
    }

    return (
      <div>
        <List>
          {
            (redemptionVoucherLink !== undefined && redemptionVoucherLink !== null && redemptionVoucherLink !== '') ?
              <ListItem button component="a" href={redemptionVoucherLink}>
                <ListItemText primary={MENU_LABEL_MAP.redemptionVoucher[lang]} />
              </ListItem>
            :null
          }
          {languageSettingComponent}
          <ListItem button component="a" href={tcLink}>
            <ListItemText primary={MENU_LABEL_MAP.tc[lang]} />
          </ListItem>
          <ListItem button component="a" href={privacyLink}>
            <ListItemText primary={MENU_LABEL_MAP.privacy[lang]} />
          </ListItem>
          {
            (qnaLink !== undefined && qnaLink !== null && qnaLink !== '') ?
          <ListItem button component="a" href={qnaLink}>
            <ListItemText primary={MENU_LABEL_MAP.qna[lang]} />
          </ListItem>
          :null
          }
          {
            onlineRedemptionRecordLink !== undefined && onlineRedemptionRecordLink !== null &&
            onlineRedemptionRecordLink !== '' &&
            <ListItem button component="a" href={onlineRedemptionRecordLink}>
              <ListItemText primary={MENU_LABEL_MAP.onlineRedemptionRecord[lang]} />
            </ListItem>
          }
        </List>
        {languageSettingDialogComponent}
      </div>
    );
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class ShopAppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false
    };
  }

  openMenu = () => {
    this.setState({menuOpen: true});
  };

  closeMenu = () => {
    this.setState({menuOpen: false});
  };

  back = () => {
    const {backType = 'back', backTarget = null, shopid} = this.props;

    switch (backType) {
      case 'back':
        if(window.history.length > 1) {
          window.history.back();
        }
        else {
          // Enter the page from other app
          window.location.replace("/index.php?sid=" + shopid);
        }
        break;
      case 'replace':
        window.location.replace(backTarget);
        break;
      default:
        break;
    }
  };

  logout = () => {
    $('#logout-modal').modal();
  };

  render() {
    const {appBarType = 'home'} = this.props;

    switch (appBarType) {
      case 'home':
        const {shopName, shopIconLink, redemptionVoucherLink, supportLanguages, lang, tcLink, privacyLink, qnaLink, onlineRedemptionRecordLink} = this.props;
        const {menuOpen} = this.state;

        return (
          <div>
            <ThemeProvider theme={theme}>
              <AppBar>
                <Toolbar>
                  <img src={shopIconLink} className="app-bar-shop-icon" alt={shopName} />
                  <Typography variant="h6" color="inherit" style={{flexGrow: 1}}>
                    <span className="app-bar-title">{shopName}</span>
                  </Typography>
                  <IconButton onClick={this.openMenu}>
                    <MoreHoriz />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <Toolbar />
              <Dialog fullScreen open={menuOpen} onClose={this.closeMenu} TransitionComponent={Transition}>
                <AppBar>
                  <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={this.closeMenu} aria-label="Close">
                      <CloseIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <Toolbar />
                <AppMenu
                  redemptionVoucherLink={redemptionVoucherLink}
                  supportLanguages={supportLanguages}
                  lang={lang}
                  tcLink={tcLink}
                  privacyLink={privacyLink} 
                  qnaLink={qnaLink}
                  onlineRedemptionRecordLink={onlineRedemptionRecordLink}
                />
              </Dialog>
            </ThemeProvider>
          </div>
        );
      case 'page':
        const {pageTitle, backBtn = true, logoutBtn = false} = this.props;

        let backBtnComponent = null;
        if (backBtn) {
          backBtnComponent = (
            <IconButton onClick={this.back} edge="start" color="inherit">
              <ArrowBack />
            </IconButton>
          );
        }

        let logoutBtnComponent = null;
        if (logoutBtn) {
          logoutBtnComponent = (
            <IconButton onClick={this.logout} edge="end" color="inherit">
              <ExitToApp />
            </IconButton>
          );
        }

        return (
          <div>
            <ThemeProvider theme={theme}>
              <AppBar>
                <Toolbar>
                  <Typography variant="h6" color="inherit" className="app-bar-center-title">
                    <span className="app-bar-title">{pageTitle}</span>
                  </Typography>
                  {backBtnComponent}
                  <div style={{flex: '1 1 auto'}}></div>
                  {logoutBtnComponent}
                </Toolbar>
              </AppBar>
              <Toolbar />
            </ThemeProvider>
          </div>
        );
      default:
        return null;
    }
  }
};
