import React from 'react';
import ReactDOM from 'react-dom';
import {AppBar, News} from './components';

const $ = window.$;

function renderNews() {
  $('.news-component-container').each(function() {
    const renderContainer = $(this).find('.news-component-render-container');

    const imgLink = renderContainer.attr('img-link');
    const fullContent = $(this).find('.news-component-content-render-data').html();
    const newsType = renderContainer.attr('news-type');

    const title = '';
    const content = fullContent;

    switch (newsType) {
      case 'news':
        ReactDOM.render(
          <News
            imgLink={imgLink}
            title={title}
            content={content}
            newsType={newsType} />,
          renderContainer[0]
        );
        break;
      case 'memberOffer':
        const redeemLink = renderContainer.attr('redeem-link');
        const minGrade = renderContainer.attr('min-grade');
        const minGradeName = renderContainer.attr('min-grade-name');
        const ptsPrice = renderContainer.attr('pts-price');
        const oneTime = renderContainer.attr('one-time');
        const redeemed = renderContainer.attr('redeemed');

        ReactDOM.render(
          <News
            imgLink={imgLink}
            title={title}
            content={content}
            newsType={newsType}
            redeemLink={redeemLink}
            minGrade={parseInt(minGrade)}
            minGradeName={minGradeName}
            ptsPrice={ptsPrice}
            oneTime={parseInt(oneTime) === 1}
            redeemed={parseInt(redeemed) === 1} />,
          renderContainer[0]
        );
        break;
      default:
        break;
    }
  });
}

window.renderNews = renderNews;

$(function() {
  if (process.env.NODE_ENV === 'development') {
    if ($('#app-bar-container').length) {
      const params = new URLSearchParams(window.location.search);
      if (params.has('lang')) {
        $('#app-bar-container').attr('lang', params.get('lang'));
      }
    }
  }

  if ($('#app-bar-container').length) {
    const container = $('#app-bar-container');
    const appBarType = container.attr('app-bar-type');

    switch (appBarType) {
      case 'home':
        const shopName = container.attr('shop-name');
        const shopIconLink = container.attr('shop-icon-link');
        const redemptionVoucherLink = container.attr('redemption-voucher-link');
        const supportLanguages = container.attr('support-languages').split(',');
        const lang = container.attr('lang');
        const tcLink = container.attr('tc-link');
        const privacyLink = container.attr('privacy-link');
        const qnaLink = container.attr('qna-link')
        const onlineRedemptionRecordLink = container.attr('online-redemption-record-link')

        ReactDOM.render(
          <AppBar
            appBarType={appBarType}
            shopName={shopName}
            shopIconLink={shopIconLink}
            redemptionVoucherLink={redemptionVoucherLink}
            supportLanguages={supportLanguages}
            lang={lang}
            tcLink={tcLink}
            privacyLink={privacyLink} 
            qnaLink={qnaLink}
            onlineRedemptionRecordLink={onlineRedemptionRecordLink}
          />,
          container[0]
        );
        break;
      case 'page':
        const pageTitle = container.attr('page-title');
        const backBtn = (container.attr('back-btn')) ? container.attr('back-btn') === '1' : true;
        const logoutBtn = (container.attr('logout-btn')) ? container.attr('logout-btn') === '1' : false;
        const shopid = container.attr('shopid');

        let backType = 'back';
        let backTarget = null;
        if (backBtn) {
          if (container.attr('back-type')) {
            backType = container.attr('back-type');
          }
          if (container.attr('back-target')) {
            backTarget = container.attr('back-target');
          }
          if (backType === 'replace' && !backTarget) {
            backTarget = '/';
          }
        }

        ReactDOM.render(
          <AppBar
            appBarType={appBarType}
            pageTitle={pageTitle}
            backBtn={backBtn}
            backType={backType}
            backTarget={backTarget}
            shopid={shopid}
            logoutBtn={logoutBtn} />,
          container[0]
        );
      default:
        break;
    }
  }

  if ($('.news-component-container').length) {
    renderNews();
  }
});
