import React from 'react';
import './News.css';

function _NewsImg(props) {
  const {imgLink} = props;

  return (
    <div className="news-component-img-container">
      <img src={imgLink} className="news-component-img" />
    </div>
  );
}

function _NewsContent(props) {
  const {title, content} = props;

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{__html: title}}
        className="news-component-title">
      </div>
      <div
        dangerouslySetInnerHTML={{__html: content}}
        className="news-component-content">
      </div>
    </div>
  );
}

function _MemberNewsDetails(props) {
  const {redeemLink = null, minGrade = null, minGradeName = null, ptsPrice = null, oneTime = false, redeemed = false} = props;

  const minGradeComponent = (
    <div className="news-component-member-news-details-min-grade-container">
      <div className="web-font-bold">{window._texts.member_offer}</div>
      <div>{(minGrade === 0) ? window._texts.all_member : window._texts.min_grade_or_above.replace('{minGradeName}', minGradeName)}</div>
    </div>
  );

  let ptsPriceComponent = null;
  if (ptsPrice > 0) {
    ptsPriceComponent = (
      <div className="news-component-member-news-details-pts-price-container">
        <div className="web-font-bold">{window._texts.pts_redemption}</div>
        <div>{ptsPrice}</div>
      </div>
    );
  }

  let redeemLinkComponent = null;
  if (!redeemed) {
    redeemLinkComponent = (
      <a href={redeemLink}>
        <img src="/common/images/btn_right.png" className="news-component-member-news-details-link-btn" />
      </a>
    );
  }

  let oneTimeComponent = null;
  if (oneTime) {
    oneTimeComponent = (
      <div>{window._texts.one_time}</div>
    );
  }

  return (
    <div>
      <div className="news-component-member-news-details-container">
        <div style={{display: 'flex', flex: '1 1 auto'}}>
          {minGradeComponent}
          <div style={{flex: '0 0 16px'}}></div>
          {ptsPriceComponent}
        </div>
        <div style={{flex: '0 0 auto'}}>
          {redeemLinkComponent}
        </div>
      </div>
      {oneTimeComponent}
    </div>
  );
}

export default function News(props) {
  const {imgLink = null, title, content, newsType} = props;

  let imgComponent = (imgLink) ? <_NewsImg imgLink={imgLink} /> : null;
  const contentComponent = <_NewsContent title={title} content={content} />;

  switch (newsType) {
    case 'news':
      return (
        <div className="news-component">
          {imgComponent}
          <div className="news-component-all-content-container">
            {contentComponent}
          </div>
        </div>
      );
    case 'memberOffer':
      const {redeemLink = null, minGrade = null, minGradeName = null, ptsPrice = null, oneTime = false, redeemed = false} = props;

      if (!redeemed) {
        imgComponent = <a href={redeemLink}>{imgComponent}</a>;
      }

      if (redeemed) {
        imgComponent = (
          <div className="news-component-img-with-overlay-container">
            {imgComponent}
            <div className="news-component-img-overlay">
              <span className="news-component-img-overlay-text">{window._texts.redeemed}</span>
            </div>
          </div>
        );
      }

      const detailsComponent = (
        <_MemberNewsDetails
          redeemLink={redeemLink}
          minGrade={minGrade}
          minGradeName={minGradeName}
          ptsPrice={ptsPrice}
          oneTime={oneTime}
          redeemed={redeemed} />
      );

      return (
        <div className="news-component">
          {imgComponent}
          <div className="news-component-all-content-container">
            {contentComponent}
            <div style={{flex: '0 0 16px'}}></div>
            {detailsComponent}
          </div>
        </div>
      );
    default:
      return null;
  }
};
